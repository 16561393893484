import { NgModule } from '@angular/core';
import { NgsPermissionModule } from '@syspons/ngs-permission';
import { PermissionServiceImpl } from './permission.service';
import { ParseEntityControllerModule } from 'src/app/controllers/parseEntity/parseEntity.module';

@NgModule({
  imports: [
    NgsPermissionModule.forRoot({
      implementation: PermissionServiceImpl,
      navigateTo: ['/'],
    }),
    ParseEntityControllerModule,
  ],
})
export class PermissionModule {}
