import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgsNavItems } from '@syspons/ngs-navigation';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FeaturesTopNavService {
  topNavItems$ = new BehaviorSubject<NgsNavItems>([]);
  loaded: boolean;

  constructor(private router: Router) {
    router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => this.unsetTopNavItems());
  }

  setTopNavItems(topNavItems: NgsNavItems) {
    this.topNavItems$.next(topNavItems);
  }

  unsetTopNavItems() {
    this.topNavItems$.next([]);
  }
}
