import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgsTableauDashboardModule } from '@syspons/ngs-tableau-dashboard';
import { TableauDashboardComponent } from './dashboard.components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TableauDashboardComponent],
  imports: [
    CommonModule,
    NgsTableauDashboardModule,
    RouterModule.forChild([{ path: '', component: TableauDashboardComponent }]),
  ],
})
export class DashboardModule {}
