import Parse from 'parse';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgsCookieConsentService } from '@syspons/ngs-storage';

import { CurrentUserController } from './controllers/user/currentUser.controller';
import ParseSystemService from './controllers/parseSystem/parseSystem.service';
import { FeaturesTopNavService } from './features/features.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private cookieConsent: NgsCookieConsentService,
    private currentUserController: CurrentUserController,
    private systemService: ParseSystemService,
    private http: HttpClient,
    private router: Router,
    public featuresService: FeaturesTopNavService,
  ) {}

  ngOnInit(): void {
    let initUrl: string;
    let loading = false;

    this.login();

    this.router.events.subscribe((url: any) => {
      if (!initUrl) {
        initUrl = url.rul;
        initUrl = initUrl === '/loading' ? '/home' : url.url;
      }
      this.featuresService.topNavItems$.subscribe(items => {
        if (items.length > 0 && !this.featuresService.loaded) {
          this.featuresService.loaded = true;
          this.router.navigate([initUrl === '/loading' ? '/home' : initUrl]);
        } else if (!loading) {
          loading = true;
          this.router.navigate(['/loading']);
        }
      });
    });
  }

  login() {
    // Keycloak login
    this.currentUserController.logIn().then(
      (res: any) => {
        if (res.isLoggedIn) {
          // Get the server config
          this.http
            .get('/getConfig', { params: { token: res.token, user: JSON.stringify(res.user) } })
            .pipe(
              untilDestroyed(this),
              catchError(e => {
                // Unauthorized
                this.onError(e);
                return this.currentUserController.logOut();
              }),
            )
            .subscribe(config => {
              // Init Parse using config
              document.title = config.title;
              this.initParse(config);
            });
        } else {
          this.onError(new HttpErrorResponse({ error: 'Current user failed to login' }));
        }
      },
      e => {
        console.error(e);
      },
    );
  }

  initParse(config: any) {
    console.log('On Parse Init');
    Parse.initialize(config.appId, config.clientKey);
    Parse.serverURL = config.serverURL;
    this.currentUserController.linkParseUser().then(
      () => {
        this.systemService.init(config);
      },
      e => {
        // User will be logged out
      },
    );
  }

  onError = (error: HttpErrorResponse) => {
    this.router.navigate(['/error', { error: JSON.stringify(error) }]);
  };
}
