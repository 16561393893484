import { TranslateService } from '@ngx-translate/core';
import { NgsOptionsService } from '@syspons/ngs-storage';

import ParseSystemService from './parseSystem.service';
import { ParseCloudController } from '../parseCloud/parseCloud.Controller';
import { injectUserTranslations } from '../parseTranslation/parseTranslation.service';
import { NgsSnackbarService } from '@syspons/ngs-snackbar';
import { NgsDialogService } from '@syspons/ngs-dialog';
import { NgsLoaderService } from '@syspons/ngs-loader';
import ParseLiveSocketService from '../parseLiveSocket/parseLiveSocket.service';

const ParseSystemFactory = (
  translate: TranslateService,
  options: NgsOptionsService,
  ngsDialogService: NgsDialogService,
  ngsSnackbarService: NgsSnackbarService,
  ngsLoaderService: NgsLoaderService,
  liveSocketService: ParseLiveSocketService,
) => {
  const service = new ParseSystemService(
    new ParseCloudController(ngsDialogService, ngsSnackbarService, ngsLoaderService, liveSocketService),
  );
  injectUserTranslations(service, translate, options, service);
  return service;
};

export default ParseSystemFactory;
