import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomePageComponent {
  imageUrl = `url(${environment.outDir}/assets/img/home.jpeg)`;

  constructor() {}
}
