import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';

import { LOCALE_OPTION_KEY } from '@syspons/ngs-locale';
import { NgsNavItem, NgsNavItems, NgsNavMode } from '@syspons/ngs-navigation';
import { NgsOptionsService } from '@syspons/ngs-storage';
import { NgsLoaderService } from '@syspons/ngs-loader';
import {
  ApplicationPanelType,
  LayoutConfig,
  LayoutItem,
  LayoutItemLayoutType,
  LayoutPanelConfig,
  LayoutItemActions,
  ApplicationSections,
  LayoutItemType,
} from '@syspons/monitoring-api';

import { environment } from '../../environments/environment';
import { canActivateRoute, CurrentUserController } from '../controllers/user/currentUser.controller';
import { FeaturesTopNavService } from './features.service';

import ParseSystemService from '../controllers/parseSystem/parseSystem.service';
import { ParseCloudController } from '../controllers/parseCloud/parseCloud.Controller';

import { Router } from '@angular/router';

const defaultItems: NgsNavItems = [
  {
    id: 'imprint_group',
    title: '',
    group: true,
    children: [
      {
        id: 'imprint',
        title: 'imprint',
        href: 'https://www.syspons.com/impressum/',
      },
      {
        id: 'privacy',
        title: 'privacy',
        href: 'https://www.syspons.com/datenschutz/',
      },
    ],
  },
];

@UntilDestroy()
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit {
  NgsNavMode = NgsNavMode;
  environment = environment;
  navItems: NgsNavItems;

  layoutConfig: LayoutConfig;

  constructor(
    public topNavService: FeaturesTopNavService,
    private currentUserController: CurrentUserController,
    private cloudController: ParseCloudController,
    private options: NgsOptionsService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private systemService: ParseSystemService,
    public ngsLoaderService: NgsLoaderService,
  ) {}

  ngOnInit(): void {
    // Init Features after first data load
    combineLatest([
      this.systemService.systemObj$,
      this.currentUserController.currentUserEntity$,
      this.currentUserController.userRoles$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([systemObj, currentUser, userRoles]) => {
        if (systemObj && currentUser && userRoles) {
          this.processDynamicRoutes();
        }
      });
  }

  processDynamicRoutes = () => {
    const processPanel = (panel: LayoutPanelConfig): NgsNavItem[] => {
      const isItemVisible = (item: LayoutItem): boolean => {
        return item.visible && (item.layoutType === LayoutItemLayoutType.group || item.type === LayoutItemType.action)
          ? true
          : this.currentUserController.hasRoutePermission(item.route);
      };

      const items: NgsNavItem[] = [];
      panel.items.forEach((item: LayoutItem) => {
        if (isItemVisible(item)) {
          const navItem: NgsNavItem = {
            id: item.name,
            title: item.name,
            group: item.layoutType === LayoutItemLayoutType.group,
            settings: panel.type === ApplicationPanelType.Top,
            icon: item.icon,
          };
          switch (item.layoutType) {
            case LayoutItemLayoutType.item:
              if (item.items && item.items.length > 0) {
                navItem.route = `${item.route}/${item.items?.[0].route}`;
              }
              break;

            case LayoutItemLayoutType.group:
              navItem.children = [];
              break;
          }

          item.items?.forEach((childItem: LayoutItem) => {
            if (item.layoutType === LayoutItemLayoutType.item && childItem.action) {
              navItem.action = () => this.performItemAction(childItem);
              navItem.route = undefined;
              return;
            }
            if (isItemVisible(childItem)) {
              let route = `${item.route}`;
              if (item.layoutType === LayoutItemLayoutType.group) {
                route += `/${childItem.route}`;
              }

              if (!childItem.action) {
                if (!this.router.config[0].children?.find(route => route.path === route)) {
                  this.router.config[0].children?.push({
                    path: childItem.postfix ? route + `${childItem.postfix}` : route,
                    loadChildren: () => {
                      switch (ApplicationSections[childItem.route as keyof typeof ApplicationSections]) {
                        case ApplicationSections.Admin:
                          return import('./admin/admin.module').then(m => m.AdminModule);

                        case ApplicationSections.Current_User:
                          return import('./current-user/current-user.module').then(m => m.CurrentUserModule);

                        default:
                          switch (childItem.type) {
                            case LayoutItemType.route:
                            case LayoutItemType.action:
                              return import('./class/class.module').then(m => m.ClassModule);

                            case LayoutItemType.tableau_dashboard:
                              return import('./dashboard/dashboard.module').then(m => m.DashboardModule);

                            default:
                              console.log(`Layout item type is not supported: ${childItem.type}`);
                              return import('./class/class.module').then(m => m.ClassModule);
                          }
                      }
                    },
                    canActivate: [canActivateRoute],
                  });
                }
              }

              const childNav: NgsNavItem = {
                id: childItem.name,
                title: childItem.name,
                icon: childItem.icon,
              };
              if (
                ApplicationSections[childItem.route as keyof typeof ApplicationSections] ===
                ApplicationSections.Current_User
              ) {
                childNav.title = this.currentUserController.currentUserEntity$.value
                  ? this.currentUserController.currentUserEntity$.value.displayname
                  : '';
              }
              if (childItem.action) {
                childNav.action = () => this.performItemAction(childItem);
                childNav.route = undefined;
              } else {
                let postfix = '';
                if (item.layoutType === LayoutItemLayoutType.group && childItem.postfix) {
                  postfix = '/' + route.substring(route.lastIndexOf('/'), route.length);
                }
                childNav.route = route + postfix;
              }
              navItem.children?.push(childNav);
            }
          });
          items.push(navItem);
        }
      });
      return items;
    };

    const addAdminSection = (topPanel: NgsNavItem[]) => {
      topPanel.push({
        id: 'AdminSection',
        title: 'Control',
        group: false,
        settings: true,
        icon: 'shield_person',
        route: 'admin',
        children: [
          {
            id: 'Admin01',
            title: 'Admin',
            group: false,
            settings: true,
            icon: 'admin_panel_settings',
            route: 'admin/admin',
          },
          {
            id: 'Roles01',
            title: 'Roles',
            group: false,
            settings: true,
            icon: 'lock',
            route: 'admin/_Role',
          },
        ],
      });
      this.router.config[0].children?.push({
        path: 'admin/admin',
        loadChildren: () => {
          return import('./admin/admin.module').then(m => m.AdminModule);
        },
        canActivate: [canActivateRoute],
      });
      this.router.config[0].children?.push({
        path: 'admin/:className',
        loadChildren: () => {
          return import('./class/class.module').then(m => m.ClassModule);
        },
        canActivate: [canActivateRoute],
      });
    };

    const addDebugSection = (topPanel: NgsNavItem[]) => {
      topPanel.push({
        id: 'debugSection',
        title: 'Debug',
        group: false,
        settings: true,
        icon: 'bug_report',
        route: 'debug',
        children: [
          {
            id: 'debug01',
            title: 'Debug',
            group: false,
            settings: true,
            icon: 'bug_report',
            route: 'debug/debug',
          },
        ],
      });
      this.router.config[0].children?.push({
        path: 'debug/debug',
        loadChildren: () => {
          return import('./debug/debug.module').then(m => m.DebugModule);
        },
        canActivate: [canActivateRoute],
      });
    };

    this.layoutConfig = this.systemService.systemObj.get('ui_config');
    if (this.router.config[0]) {
      const topPanel = processPanel(this.layoutConfig.topPanel);
      // Add debug section during development
      if (this.currentUserController.user.get('isAdmin')) {
        addDebugSection(topPanel);
      }
      let dynamicRoutes = processPanel(this.layoutConfig.sidePanel).concat(topPanel);
      this.navItems = dynamicRoutes.concat(defaultItems);
      this.topNavService.setTopNavItems(this.navItems);
      this.cd.detectChanges();
    }
  };

  performItemAction(item: LayoutItem): any {
    switch (item.action?.action) {
      case LayoutItemActions.set_locale:
        this.options.set(LOCALE_OPTION_KEY, item.action.value);
        break;

      case LayoutItemActions.open_url:
        window.open(item.action.value, '_blank');
        break;

      case LayoutItemActions.log_out:
        this.currentUserController.logOut();
        break;

      case LayoutItemActions.download_file:
        this.cloudController.downloadFile(item.action.value);
        break;

      default:
        console.log(`Item action is not supported: ${item.action?.action}`);
        break;
    }
  }
}
