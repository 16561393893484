import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { FeaturesComponent } from './features/features.component';
import { HomePageComponent } from './pages/home/home.component';
import { LoadingPageComponent } from './pages/loading/loading-page.component';
import { ErrorPageComponent } from './pages/error/error.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: FeaturesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'loading', component: LoadingPageComponent },
      {
        path: 'home',
        component: HomePageComponent,
      },
      {
        path: 'error',
        component: ErrorPageComponent,
      },
    ],
  },
];
