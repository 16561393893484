import Parse, { RestSchema } from 'parse';
import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParseCloudController } from '../parseCloud/parseCloud.Controller';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CloudFunctionResult,
  LocaleConfig,
  MonitoringCloudFunctions,
  MonitoringSchemaConfig,
  SystemObject,
} from '@syspons/monitoring-api';
import moment from 'moment';

export type ServerConfig = {
  mode: string;
  appId: string;
  clientKey: string;
  serverURL: string;
};

export type KeysWithValue<T> = {
  [key: string]: T;
};
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export default class ParseSystemService {
  serverConfig: ServerConfig;
  systemQuery: Parse.Query<Parse.Object<SystemObject>>;
  schema: KeysWithValue<Parse.RestSchema>;
  systemObj: Parse.Object<SystemObject>;

  systemObj$: ReplaySubject<Parse.Object<SystemObject>> = new ReplaySubject();
  schema$: ReplaySubject<KeysWithValue<Parse.RestSchema>> = new ReplaySubject();
  localeConfig$: ReplaySubject<LocaleConfig> = new ReplaySubject();
  localeConfig: LocaleConfig;

  error$: ReplaySubject<Error> = new ReplaySubject();

  blockSystemObjUpdates: boolean;

  constructor(private parseCloudController: ParseCloudController) {}

  init = (serverConfig: ServerConfig) => {
    this.serverConfig = serverConfig;

    this.systemQuery = new Parse.Query<Parse.Object<SystemObject>>(MonitoringSchemaConfig.system.className).equalTo(
      'name',
      MonitoringSchemaConfig.system.systemObjName,
    );
    this.systemQuery.subscribe().then(subscription => {
      this.systemQuery.first().then(systemObj => {
        if (systemObj) {
          this.setSystemObj(systemObj);
          console.log(`System object loaded: ${systemObj.id} / ${systemObj.get('name')}`);
        } else {
          console.log(
            `SystemController: System object was not found: ${MonitoringSchemaConfig.system.className} -> ${MonitoringSchemaConfig.system.systemObjName}`,
          );
        }
      }, this.OnError);
      subscription.on('open', () => {
        console.log('SystemController. System subscription opened');
      });
      subscription.on('update', systemObj => {
        if (!this.blockSystemObjUpdates) {
          this.setSystemObj(systemObj as Parse.Object<SystemObject>);
        }
      });
    }, this.OnError);
  };

  OnError = (e: Error | string) => {
    console.log(e instanceof Error ? e.message : e);
    this.error$.next(e instanceof Error ? e : new Error(e));
  };

  private setSystemObj = (systemObj: Parse.Object<SystemObject>) => {
    if (systemObj) {
      this.systemObj = systemObj;
      this.systemObj$.next(systemObj);

      this.localeConfig = systemObj.get('local_config');
      moment.locale(this.localeConfig.defaultLocale.toString());
      moment.defaultFormat = this.localeConfig.defaultDateFormat;
      this.localeConfig$.next(this.localeConfig);

      this.parseCloudController
        .runCloudFunction<any, RestSchema[]>(MonitoringCloudFunctions.GetSchema, {})
        .then((result: CloudFunctionResult<RestSchema[]>) => {
          this.schema = result.result.reduce((a, v) => ({ ...a, [v.className]: v }), {});
          this.schema$.next(this.schema);
        }, this.OnError);
    }
  };

  public fetchSystemObj = () => {
    this.systemObj.fetch().then(this.setSystemObj, this.OnError);
  };
}
