<div class="fill-content">
  <ngs-loader [state]="ngsLoaderService.isLoading$" [legacy]="false"></ngs-loader>
  <ngs-navigation
    [items]="navItems"
    [title]="environment.appName | uppercase"
    [titleTooltip]="environment.version"
    [logo]="environment.logoUrl"
    [mode]="NgsNavMode.side">
    <router-outlet></router-outlet>
  </ngs-navigation>
</div>
