import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgsLoaderModule } from '@syspons/ngs-loader';
import { NgsNavigationModule } from '@syspons/ngs-navigation';

import { FeaturesComponent } from './features.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgsLoaderModule,
    NgsNavigationModule,
    FlexLayoutModule,
    DashboardModule,
  ],
  declarations: [FeaturesComponent],
})
export class FeaturesModule {}
