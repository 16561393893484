import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { INgsTableauDashboardConfig } from '@syspons/ngs-tableau-dashboard';
import { combineLatest, debounceTime, distinctUntilChanged } from 'rxjs';
import ParseSystemService from 'src/app/controllers/parseSystem/parseSystem.service';

@UntilDestroy()
@Component({
  selector: 'app-tableau-dashboard',
  template: `<ngs-tableau-dashboard [config]="config"></ngs-tableau-dashboard>`,
})
export class TableauDashboardComponent implements OnInit {
  config: Partial<INgsTableauDashboardConfig> = {};

  constructor(private systemService: ParseSystemService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    console.log('TableauDashboardComponent ngOnInit');
    combineLatest([this.route.params, this.systemService.systemObj$])
      .pipe(debounceTime(1000), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(([params, systemObj]) => {
        const tableauConfig = systemObj.get('tableau_config') || { dashboards: [] };
        const current = tableauConfig.dashboards.find(dashboard => dashboard.id === params['id']);
        if (current) {
          this.config = { url: current.url };
        }
      });
  }
}
