import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgsSnackbarModule } from '@syspons/ngs-snackbar';
import { NgsDialogModule } from '@syspons/ngs-dialog';

import { ParseEntityController } from './parseEntity.controller';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgsSnackbarModule, NgsDialogModule],
  providers: [ParseEntityController],
})
export class ParseEntityControllerModule {}
