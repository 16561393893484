import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { NgsInjector } from '@syspons/ngs-injector';
import { enableDebugTools } from '@angular/platform-browser';
import { NgsReboot } from '@syspons/ngs-reboot';

if (environment.production) {
  enableProdMode();
}

const init = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {
      NgsInjector.setInjector(moduleRef.injector);

      if (environment.production) {
        return;
      }

      // enable profiler
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      enableDebugTools(componentRef);
    })
    .catch(err => console.error(err));

// Init on first load
init();

// Init on reboot request
const boot = NgsReboot.instance().reboot$.subscribe(() => init());
